'use strict';

import { tripData, step3Section, weatherSection, resultsSection, stepsSection } from './formGlobals';

import { disableElements, enableElements, updateResults } from './formHelpers';

import { checkSuccess, testDate, compareDates, checkDuration } from './formTesters';

const handleWeatherSubmit = async (event) => {
  // prevent form from submitting
  event.preventDefault();

  step3Section.classList.remove('error');

  step3Section.classList.remove('complete');

  const weatherForm = event.currentTarget;

  // extract values from form
  const { elements, action, method } = weatherForm;

  disableElements(elements);

  const start = elements.start.value;

  try {
    testDate(start); // throws

    const dateParts = start.split('-');

    const startDate = new Date(dateParts[0], dateParts[1] - 1, dateParts[2], 0, 0, 0, 0);

    const duration = elements.duration.value;

    checkDuration(duration); // throws

    const now = new Date();

    now.setHours(0, 0, 0, 0);

    compareDates(now.getTime(), startDate.getTime());

    const unixTime = Math.round(startDate.getTime() / 1000);

    Object.assign(tripData, { start: unixTime, duration });

    // set fetch options
    const init = {
      method: method, // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify({ lat: tripData.lat, lng: tripData.lng, start: unixTime, duration }),
    };

    const res = await fetch(action, init);

    const json = await res.json();

    checkSuccess(json);

    const { forecast } = json;

    Object.assign(tripData, { forecast });

    updateResults();

    // show results after hiding step 3
    weatherSection.addEventListener(
      'transitionend',
      () => {
        enableElements(elements);

        resultsSection.classList.remove('collapsed');
      },
      {
        passive: true,
        once: true, // self-removing event listener
        capture: true,
      }
    );

    step3Section.classList.add('complete');

    weatherSection.classList.add('collapsed');

    stepsSection.classList.add('collapsed');
  } catch (err) {
    enableElements(elements);

    const message = err.message || 'unknown error';

    console.error(message);

    step3Section.classList.add('error');

    step3Section.firstElementChild.lastElementChild.innerHTML = message;
  }
};

export { handleWeatherSubmit };
