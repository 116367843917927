'use strict';

if ('serviceWorker' in navigator) {
  window.addEventListener(
    'load',
    async () => {
      try {
        const registration = await navigator.serviceWorker.register('/sw.js');

        console.log('SW registered: ', registration);
      } catch (e) {
        console.error('SW registration failed: ', e);
      }
    },
    {
      capture: true,
      once: true,
      passive: true,
    }
  );
}
