'use strict';

const stepsSection = document.getElementById('steps');

const step1Section = document.getElementById('step1');

const searchSection = document.getElementById('search');

const step2Section = document.getElementById('step2');

const confirmSection = document.getElementById('confirm');

const destinationsSelect = document.getElementById('destinations');

const step3Section = document.getElementById('step3');

const weatherSection = document.getElementById('weather');

const startInput = document.getElementById('start');

const resultsSection = document.getElementById('results');

const tripData = {};

export {
  tripData,
  stepsSection,
  step1Section,
  step2Section,
  step3Section,
  searchSection,
  confirmSection,
  destinationsSelect,
  weatherSection,
  startInput,
  resultsSection,
};
