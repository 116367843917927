import { startInput } from './formGlobals';
import { isValidDate } from './formTesters';

// initialize input#start as [type=date] or fallback to [type=text] (workaround for mac safari desktop)
(() => {
  const date = new Date();

  date.setTime(date.getTime() - date.getTimezoneOffset() * 60000);

  date.setUTCHours(0, 0, 0, 0);

  if (startInput.type === 'date') {
    date.setDate(date.getDate() + 1);

    startInput.min = date.toISOString().split('T')[0];

    date.setDate(date.getDate() + 1);

    startInput.valueAsDate = date;

    date.setDate(date.getDate() + 364);

    startInput.max = date.toISOString().split('T')[0];
  } else {
    date.setDate(date.getDate() + 2);

    startInput.value = date.toISOString().split('T')[0];

    startInput.placeholder = 'YYYY-MM-DD';

    startInput.pattern = isValidDate.source;
  }
})();
