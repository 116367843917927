'use strict';

const checkSuccess = (res) => {
  if (res.success !== true) {
    throw new Error(res.message || 'Transaction not successful.');
  }
};

// YYYY-MM-DD (with leap year day), slightly modified from original @ http://html5pattern.com/Dates for year to only start with 20xx
const isValidDate = /^(?:20)(?:(?:[13579][26]|[02468][048])-(?:(?:0[1-9]|1[0-2])-(?:0[1-9]|1[0-9]|2[0-9])|(?:(?!02)(?:0[1-9]|1[0-2])-(?:30))|(?:(?:0[13578]|1[02])-31))|(?:[0-9]{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|1[0-9]|2[0-8])|(?:(?!02)(?:0[1-9]|1[0-2])-(?:29|30))|(?:(?:0[13578]|1[02])-31)))$/;

const testDate = (date) => {
  if (!isValidDate.test(date)) {
    throw new Error('Invalid date format.');
  }
};

const compareDates = (today, future) => {
  if (future < today) {
    throw new Error('Start date must not be in the past.');
  }
};

const checkDuration = (duration) => {
  if (duration < 1 || duration > 3) {
    throw new Error('Duration must range from 1 to 3 days.');
  }
};

export { checkSuccess, testDate, compareDates, checkDuration, isValidDate };
