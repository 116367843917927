'use strict';

import { tripData, step2Section, confirmSection, step3Section } from './formGlobals';

import { disableElements, enableElements } from './formHelpers';

import { checkSuccess } from './formTesters';

import fallbackImg from '../images/fallback.jpg';

const handleConfirmSubmit = async (event) => {
  // prevent form from submitting
  event.preventDefault();

  step2Section.classList.remove('error');

  step2Section.classList.remove('complete');

  const confirmForm = event.currentTarget;

  // extract values from form
  const { elements, action, method } = confirmForm;

  disableElements(elements);

  const destinations = elements.destinations;

  const destination = destinations.value;

  const {
    dataset: { lat, lng },
  } = destinations.options[destinations.selectedIndex];

  Object.assign(tripData, { lat, lng, destination });

  // set fetch options
  const init = {
    method: method, // *GET, POST, PUT, DELETE, etc.
    mode: 'cors', // no-cors, *cors, same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify({ destination: destinations.value }),
  };

  try {
    const res = await fetch(action, init);

    const json = await res.json();

    checkSuccess(json);

    const { tags, webformatURL = fallbackImg } = json; // get image url from pixaday

    Object.assign(tripData, { tags, webformatURL });

    // show step 3 after hiding step 2
    confirmSection.addEventListener(
      'transitionend',
      () => {
        enableElements(elements);

        step3Section.classList.remove('collapsed');
      },
      {
        passive: true,
        once: true, // self-removing event listener
        capture: true,
      }
    );

    step2Section.classList.add('complete');

    confirmSection.classList.add('collapsed');
  } catch (err) {
    enableElements(elements);

    const message = err.message || 'unknown error';

    console.error(message);

    step2Section.classList.add('error');

    step2Section.firstElementChild.lastElementChild.innerHTML = message;
  }
};

export { handleConfirmSubmit };
