'use strict';

import { tripData, resultsSection } from './formGlobals';

const enableElements = (elements) => {
  for (let i = 0; i < elements.length; ++i) {
    elements[i].disabled = false;
  }
};

const disableElements = (elements) => {
  for (let i = 0; i < elements.length; ++i) {
    elements[i].disabled = true;
  }
};

const updateResults = () => {
  const { tags, webformatURL, destination, country, countryName, forecast, start, lat, lng } = tripData;

  const da = resultsSection.querySelector('div a');

  const dai = resultsSection.querySelector('div a img');

  const ddp = resultsSection.querySelectorAll('div div p');

  const ddu = resultsSection.querySelector('div div ul');

  da.href = `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`;

  dai.alt = tags;

  dai.src = webformatURL;

  ddp[0].innerHTML = `<span class="label">Location</span>: <span class="content">${destination.split(',')[0]}</span>`;

  ddp[1].innerHTML = `<span class="label">Country</span>: <span class="content">${countryName}</span>`;

  const today = new Date();

  const future = new Date(start * 1000);

  const diff = Math.ceil((future.getTime() - today.getTime()) / (1000 * 60 * 60 * 24));

  ddp[2].innerHTML = `<span class="label">When</span>: <span class="content">The trip is ${
    diff === 0 ? 'today' : diff === 1 ? 'tomorrow' : `${diff} days away`
  }.</span>`;

  const fragment = document.createDocumentFragment();

  while (ddu.hasChildNodes()) {
    ddu.removeChild(ddu.firstChild);
  }

  for (let i = 0; i < forecast.length; ++i) {
    const { time, summary, precipType, precipProbability, temperatureHigh, temperatureLow } = forecast[i];

    const date = new Date(time * 1000);

    const li = document.createElement('li');

    const p = document.createElement('p');

    const ul = document.createElement('ul');

    p.innerHTML = `Day ${i + 1}, ${date.toDateString()}`;

    ul.innerHTML = summary ? `<li>${summary}</li>` : '';

    ul.innerHTML +=
      precipProbability && precipProbability
        ? `<li>${Math.round(precipProbability * 100)}% chance of ${precipType}.</li>`
        : '';

    ul.innerHTML +=
      temperatureHigh && temperatureLow
        ? `<li>Temperature high ${Math.round(temperatureHigh)}, low ${Math.round(temperatureLow)}</li>`
        : '';

    li.appendChild(p);

    li.appendChild(ul);

    fragment.appendChild(li);
  }

  ddu.appendChild(fragment);
};

export { enableElements, disableElements, updateResults };
