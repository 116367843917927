'use strict';

import { tripData, step1Section, searchSection, step2Section, destinationsSelect } from './formGlobals';

import { disableElements, enableElements } from './formHelpers';

import { checkSuccess } from './formTesters';

const handleSearchSubmit = async (event) => {
  // prevent form from submitting
  event.preventDefault();

  step1Section.classList.remove('error');

  step1Section.classList.remove('complete');

  const locationForm = event.currentTarget;

  // remove options from previous searches
  destinationsSelect.options.length = 0;

  // extract values from form
  const { elements, action, method } = locationForm;

  disableElements(elements);

  const countries = elements.countries;

  const country = countries.value;

  const countryName = countries.options[countries.selectedIndex].innerText;

  const place = elements.place.value;

  Object.assign(tripData, { country, countryName, place });

  // set fetch options
  const init = {
    method: method, // *GET, POST, PUT, DELETE, etc.
    mode: 'cors', // no-cors, *cors, same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify({ country, place }),
  };

  try {
    const res = await fetch(action, init);

    const json = await res.json();

    checkSuccess(json);

    const { locations } = json;

    const fragment = document.createDocumentFragment();

    for (let i = 0; i < locations.length; ++i) {
      const { geonameId, name, lat, lng, adminName1, countryName } = locations[i];

      const option = document.createElement('option');

      const optionVal = `${name}, ${adminName1 ? `${adminName1}, ` : ''}${countryName}`;

      option.value = optionVal;

      option.dataset.geonameId = geonameId;

      option.dataset.lat = lat;

      option.dataset.lng = lng;

      option.innerHTML = optionVal;

      fragment.appendChild(option);
    }

    destinationsSelect.appendChild(fragment);

    // show step 2 after hiding step 1
    searchSection.addEventListener(
      'transitionend',
      () => {
        enableElements(elements);

        step2Section.classList.remove('collapsed');
      },
      {
        passive: true,
        once: true, // self-removing event listener
        capture: true,
      }
    );

    step1Section.classList.add('complete');

    searchSection.classList.add('collapsed');
  } catch (err) {
    enableElements(elements);

    const message = err.message || 'unknown error';

    console.error(message);

    step1Section.classList.add('error');

    step1Section.firstElementChild.lastElementChild.innerHTML = message;
  }
};

export { handleSearchSubmit };
